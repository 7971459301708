<template>
  <div class="news-detail-more">
    <router-link
      :to="{ name: 'NewsDetail', params: { id: news.id } }"
      class="news-detail-more__title"
    >
      {{ news.headline }}
    </router-link>
    <div class="news-detail-more__text" v-html="news.simple_text"></div>
  </div>
</template>

<script>
export default {
  name: "NewsDetailListItem",
  props: {
    news: {
      type: Object,
      required: true,
    },
  },
};
</script>
