<template>
  <div class="news-detail">
    <div v-if="isLoading" class="loading"></div>
    <div v-else-if="!detailNews" class="empty-block">Нет информации</div>
    <template v-else>
      <Page>
        <template v-slot:breadcrumbs>
          <router-link :to="{ name: 'Home' }" class="breadcrumbs__item">
            Главная
          </router-link>
          <router-link :to="{ name: 'News' }" class="breadcrumbs__item">
            Новости
          </router-link>
          <span class="breadcrumbs__item">{{ detailNews.headline }}</span>
        </template>

        <h1 class="page-title mw-100">{{ detailNews.headline }}</h1>

        <div class="news-detail__content">
          <div class="news-detail__col-left">
            <div class="news-detail__date">{{ detailNews.formatted_date }}</div>
            <img
              :src="imgUrl"
              :alt="detailNews.headline"
              class="news-detail__img"
            />
            <div
              class="news-detail__subtitle"
              v-html="detailNews.simple_text"
            ></div>
            <div class="news-detail__text" v-html="detailNews.main_text">
              <!-- <p></p> -->
            </div>
          </div>

          <div class="news-detail__col-right">
            <NewsDetailListItem
              v-for="news in newsList"
              :key="news.id"
              :news="news"
            />
          </div>
        </div>
      </Page>
    </template>
  </div>
</template>

<script>
import Page from "@/components/base/Page.vue";
import NewsDetailListItem from "@/components/partials/NewsDetailListItem.vue";

import { mapGetters, mapActions } from "vuex";
import settings from "@/settings";

export default {
  name: "NewsDetail",
  components: { Page, NewsDetailListItem },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters("news", ["detailNews", "otherNews"]),
    newsList() {
      return this.otherNews(this.$route.params.id);
    },
    imgUrl() {
      return this.detailNews.photo
        ? settings.APP_BASE + this.detailNews.photo
        : null;
    },
  },
  async mounted() {
    this.getNews();
    await this.getDetailNews(this.$route.params.id);
    this.isLoading = false;
  },
  methods: {
    ...mapActions("news", ["getDetailNews", "getNews", "resetDetailNews"]),
  },
  beforeDestroy() {
    this.resetDetailNews();
  },

  async beforeRouteUpdate(to, from, next) {
    this.isLoading = true;
    await this.getDetailNews(to.params.id);
    this.isLoading = false;

    next();
  },
};
</script>
